<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Error Log</h4>
      <div class="error-log">
        <p>There are no errors to report</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>